// Generated by Framer (ff6f0b6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/BUhW_KNkJ";
import * as sharedStyle from "../css/rkIUMJwFJ";

const enabledGestures = {XoRZ4d4MW: {hover: true}};

const cycleOrder = ["XoRZ4d4MW", "QhSCVnP_N"];

const serializationHash = "framer-Cke0x"

const variantClassNames = {QhSCVnP_N: "framer-v-qdl256", XoRZ4d4MW: "framer-v-5li4w6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "XoRZ4d4MW", "Variant 2": "QhSCVnP_N"}

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, CmNfgKdca: link ?? props.CmNfgKdca, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XoRZ4d4MW", ZtGcZPGlm: text ?? props.ZtGcZPGlm ?? "Services"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ZtGcZPGlm, CmNfgKdca, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "XoRZ4d4MW", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={CmNfgKdca} nodeId={"XoRZ4d4MW"} openInNewTab={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-5li4w6", className, classNames)} framer-d4lt29`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XoRZ4d4MW"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"XoRZ4d4MW-hover": {"data-framer-name": undefined}, QhSCVnP_N: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h5 className={"framer-styles-preset-199axix"} data-styles-preset={"rkIUMJwFJ"}>Services</motion.h5></React.Fragment>} className={"framer-163bw8c"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"UQwTfj9Ug"} text={ZtGcZPGlm} variants={{QhSCVnP_N: {"--extracted-1lwpl3i": "var(--token-06c103dc-8ad7-42c1-870d-911c94b0165c, rgb(200, 198, 197))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"XoRZ4d4MW-hover": {children: <React.Fragment><motion.h5 className={"framer-styles-preset-1spvn35"} data-styles-preset={"BUhW_KNkJ"}>Services</motion.h5></React.Fragment>}, QhSCVnP_N: {children: <React.Fragment><motion.h5 className={"framer-styles-preset-199axix"} data-styles-preset={"rkIUMJwFJ"} style={{"--framer-text-color": "var(--extracted-1lwpl3i, var(--token-06c103dc-8ad7-42c1-870d-911c94b0165c, rgb(200, 198, 197)))"}}>Services</motion.h5></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Cke0x.framer-d4lt29, .framer-Cke0x .framer-d4lt29 { display: block; }", ".framer-Cke0x.framer-5li4w6 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-Cke0x .framer-163bw8c { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Cke0x.framer-5li4w6 { gap: 0px; } .framer-Cke0x.framer-5li4w6 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Cke0x.framer-5li4w6 > :first-child { margin-left: 0px; } .framer-Cke0x.framer-5li4w6 > :last-child { margin-right: 0px; } }", ".framer-Cke0x.framer-v-qdl256.framer-5li4w6 { cursor: unset; }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 180
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"QhSCVnP_N":{"layout":["auto","auto"]},"pqlVZm9C9":{"layout":["auto","auto"]}}}
 * @framerVariables {"ZtGcZPGlm":"text","CmNfgKdca":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWhPsxQ2fN: React.ComponentType<Props> = withCSS(Component, css, "framer-Cke0x") as typeof Component;
export default FramerWhPsxQ2fN;

FramerWhPsxQ2fN.displayName = "menu-links-link";

FramerWhPsxQ2fN.defaultProps = {height: 64, width: 180};

addPropertyControls(FramerWhPsxQ2fN, {variant: {options: ["XoRZ4d4MW", "QhSCVnP_N"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, ZtGcZPGlm: {defaultValue: "Services", displayTextArea: false, title: "Text", type: ControlType.String}, CmNfgKdca: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerWhPsxQ2fN, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})